import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import { callMsGraphGroups } from "../utils/MsGraphApiCall";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import NavBarTop from "../ui-components/NavBarTop";
import { Navigate } from "react-router-dom";

import { Student, Employee, GroupNotFound } from "./UserViews"

export function Home() {
  const employeeGroupIDs = ["0c52aed3-a5eb-4a64-b770-6c15793ad8a5", "f217d1f2-13f9-4da2-83c1-672792ac6357"]
  const studentGroupIDs = ["780ab7c2-51b0-4328-aaa3-6c391a5fbffa"]

  const { instance, inProgress } = useMsal();
  const [groupdID, setGroupID] = useState(null);

  useEffect(() => {
    if (!groupdID && inProgress === InteractionStatus.None) {
      callMsGraphGroups().then(response => {
        if (response.value) {
          for (const i in response.value) {
            const group = response.value[i]
            if (employeeGroupIDs.includes(group.id)) {
              setGroupID(group.id)
              document.querySelector(".page").classList.add("page-employee");
              return
            }
            if (studentGroupIDs.includes(group.id)) {
              setGroupID(group.id)
              return
            }
          }
        }
      }).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount()
          });
        }
      });
    }
  }, [inProgress, groupdID, instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <div className={'page std'}>
          <div className="container">
            <div className="row">
              <div className="col">
                <NavBarTop ></NavBarTop>

                {(employeeGroupIDs.includes(groupdID) &&
                  <Employee />)
                  || (studentGroupIDs.includes(groupdID) &&
                    <Student />)
                  ||
                  <GroupNotFound />
                }
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" replace={true} />
      </UnauthenticatedTemplate>
    </>
  );
}