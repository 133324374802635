import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './../styles/ms-logo.svg';

export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <>
            <p className="login-label">{t('Use your university account')}</p>
            <button
                className="btn-login"
                onClick={() => handleLogin("redirect")}
            >
                <Logo />
            </button>
        </>
    )
};