import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

// Msal imports
import { useIsAuthenticated, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import SignInSignOutButton from "../ui-components/SignInSignOutButton";
import { callMsGraph } from "../utils/MsGraphApiCall";
import NavBarTop from "../ui-components/NavBarTop";
import NavBarBottom from "../ui-components/NavBarBottom";

import { ReactComponent as LogoPl } from './../styles/logo-id-pl.svg';
import { ReactComponent as LogoEn } from './../styles/logo-id-en.svg';

import { useTranslation } from 'react-i18next';

export function Login() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph().then(response => setGraphData(response)).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
        if (graphData) {
            navigate("/");
        }
    }, [inProgress, graphData, instance]);


    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <div className="page login">
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col id">
                            {i18n.language == "pl"
                                ? <LogoPl />
                                : <LogoEn />
                            }
                        </div>
                        <div className="col">
                            <NavBarTop ></NavBarTop>
 

                            <section className="auth">
                                <h1>{t('Log to your Moja ZPSB service')}</h1>

                                <SignInSignOutButton />
                                <AutoLogin />
                            </section>

                            <NavBarBottom></NavBarBottom>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export function AutoLogin() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const authRequest = {
        ...loginRequest
    };

    useEffect(() => {
        console.log(isAuthenticated)
        if (isAuthenticated) {
            console.log(instance.getAllAccounts())
            instance.setActiveAccount(instance.getAllAccounts()[0])
            navigate("/");
        }
    }, [isAuthenticated]);


    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Silent}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        > </MsalAuthenticationTemplate>
    )
};