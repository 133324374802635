import { useTranslation } from 'react-i18next';
import SignInSignOutButton from "../ui-components/SignInSignOutButton";

import { ReactComponent as DeansOffice } from './../styles/deans-office.svg';
import { ReactComponent as MobileStudent } from './../styles/student.svg';
import { ReactComponent as Elearning } from './../styles/elearning-platform.svg';
import { ReactComponent as Library } from './../styles/library.svg';
import { ReactComponent as Email } from './../styles/email.svg';
import { ReactComponent as Teams } from './../styles/ms-teams.svg';
import { ReactComponent as KnowledgeBase } from './../styles/knowledge-base.svg';
import { ReactComponent as AppStore } from './../styles/appstore.svg';
import { ReactComponent as GooglePlay } from './../styles/googleplay.svg';

import Collapsible from 'react-collapsible';
import Platform from 'react-platform-js'

export function Employee() {
  const { t } = useTranslation();

  return (
    <section className='services employee'>
      <h1>{t('My ZPSB')}</h1>
      <h1 className='featured'>{t('Service for employees')}</h1>

      <SignInSignOutButton />

      <ul>
        <li>
          <span className='ico'><DeansOffice /></span>
          <a href='https://dziekanat.zpsb.pl/Konto/LogowanieProwadzacego' target="_blank" >
            {t('Virtual dean\'s office')}
          </a>

          <Platform rules={{ OS: 'iOS' }}>
            <a href='https://apps.apple.com/pl/app/mobilny-student/id1481948979' target="_blank" >
              <AppStore />
            </a>
          </Platform>
          <Platform rules={{ OS: 'Android' }}>
            <a href='https://play.google.com/store/apps/details?id=com.APRSystem.MobilnyStudent.Mobile' target="_blank" >
              <GooglePlay />
            </a>
          </Platform>
        </li>

        <CommonItems />
      </ul>
    </section>
  );
}

export function Student() {
  const { t } = useTranslation();

  return (
    <section className='services'>
      <h1>{t('My ZPSB')}</h1>
      <h1 className='featured'>{t('Service for students')}</h1>

      <SignInSignOutButton />

      <ul>
        <li>
          <span className='ico'><DeansOffice /></span>
          <a href='https://dziekanat.zpsb.pl/Konto/LogowanieStudenta' target="_blank" >
            {t('Virtual dean\'s office')}
          </a>

          <Platform rules={{ OS: 'iOS' }}>
            <a href='https://apps.apple.com/pl/app/mobilny-student/id1481948979' target="_blank" >
              <AppStore />
            </a>
          </Platform>
          <Platform rules={{ OS: 'Android' }}>
            <a href='https://play.google.com/store/apps/details?id=com.APRSystem.MobilnyStudent.Mobile' target="_blank" >
              <GooglePlay />
            </a>
          </Platform>
        </li>

        <CommonItems />
      </ul>
    </section>
  );
}

export function GroupNotFound() {
  const { t } = useTranslation();

  return (
    <section className='services'>
      <h1>{t('My ZPSB')}</h1>
      <h1 className='featured'>{t('No service assigned')}</h1>

      <p>
        <br />
        {t('You are not assigned to any group')}. {t('Please contact with administrator')}. <br />
      </p>
      <SignInSignOutButton />

    </section>
  );
}

export function CommonItems() {
  const { t } = useTranslation();

  return (
    <>
      <li>
        <span className='ico'><Elearning /></span>
        <a href='https://estudia.zpsb.pl' target='_blank' >
          {t('Elearning platform')}
        </a>

        <Platform rules={{ OS: 'iOS' }}>
          <a href='https://apps.apple.com/app/moodle-mobile/id633359593' target="_blank" >
            <AppStore />
          </a>
        </Platform>
        <Platform rules={{ OS: 'Android' }}>
          <a href='https://play.google.com/store/apps/details?id=com.moodle.moodlemobile' target="_blank" >
            <GooglePlay />
          </a>
        </Platform>
      </li>
      <li>
        <span className='ico'><Teams /></span>
        <a href='https://teams.microsoft.com/' target='_blank' >
          {t('Microsoft Teams')}
        </a>
      </li>
      <li>
        <span className='ico'><Email /></span>
        <a href='https://outlook.office365.com/mail/' target='_blank' >
          {t('ZPSB email')}
        </a>
      </li>
      <li>
        <span className='ico'><Library /></span>
        <a href='https://www.szczecin-zpsb.sowa.pl/sowacgi.php?KatID=0&typ=acc&id=info' target='_blank' >
          {t('Library')}
        </a>
        <p className='caption'>
          * {t('To log in to the library catalog, create an account using your university email address')}. <br />
          {t('To borrow a book, the account must be verified by a librarian')}
        </p>
      </li>
      <li>
        <span className='collapse'>
          <span className='ico'><KnowledgeBase /></span>
          <span className='content'>
            <Collapsible trigger={t('Knowledge base')}>
              <a href='https://www.zpsb.pl/strefa-studenta/plikownia/' className='smaller' target='_blank' >
              &#9679; {t('Files')}
              </a>
              <a href='https://authn.navoica.pl/login?next=%2F' className='smaller' target='_blank' >
              &#9679; {t('Navoica')}
              </a>
              <p className='caption'>
                * {t('To log in to the Navoica portal, create a new account using your university email address.')}
              </p>
            </Collapsible>
          </span>
        </span>
      </li>
    </>
  )
}