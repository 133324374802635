import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const NavBarContrastSwitcher = () => {
    const { t } = useTranslation();

    const [templateID, setTemplateID] = useState("tpl-wb");

    useEffect(() => {
        const data = window.localStorage.getItem('TEMPLATE_ID');
        if (data !== null) setTemplateID(data);
    }, []);

    useEffect(() => {
        window.localStorage.setItem('TEMPLATE_ID', templateID);
        document.querySelector(".page").classList.add(templateID);
    }, [templateID]);

    const replaceTemplateID = (templateIDToSet) => {
        if (templateID == templateIDToSet) {
            return
        }
        document.querySelector(".page").classList.remove(templateID);
        setTemplateID(templateIDToSet)
    }

    return (
        <>
            <div className="col col-md-auto contrast-switcher">
                <h5>
                    {t('Contrast')}:
                </h5>
                <ul>
                    <li>
                        <button onClick={() => replaceTemplateID("tpl-wb")} className="btn-tpl-wb">A</button>
                    </li>
                    <li>
                        <button onClick={() => replaceTemplateID("tpl-bw")} className="btn-tpl-bw">A</button>
                    </li>
                    <li>
                        <button onClick={() => replaceTemplateID("tpl-yb")} className="btn-tpl-yb">A</button>
                    </li>
                    <li>
                        <button onClick={() => replaceTemplateID("tpl-by")} className="btn-tpl-by">A</button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default NavBarContrastSwitcher;