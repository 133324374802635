import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
 
    }
  },
  pl: {
    translation: {
      "My ZPSB": "Moja ZPSB",
      "Welcome to ZPSB": "Witaj w ZPSB",
      "Font": "Czcionka",
      "Contrast": "Kontrast",
      "Language": "Język",
      "Logout": "Wyloguj",
      "Use your university account": "Zaloguj się używając konta uczelnianego",
      "Service for employees": "Serwis dla pracowników",
      "Service for students": "Serwis dla studentów",
      "Log to your Moja ZPSB service": "Logowanie do serwisu Moja ZPSB",
      "To log in to the library catalog, create an account using your university email address": 
        "Żeby zalogować się do katalogu bibliotecznego, załóż konto na uczelniany adres email",
      "To borrow a book, the account must be verified by a librarian":
        "Żeby wypożyczyć książkę, konto musi zweryfikować bibliotekarz",
      "No service assigned": "Nie przydzielono usług",
      "You are not assigned to any group": "Nie jesteś przydzielony do odpowiedniej grupy",
      "Please contact with administrator": "Skontaktuj się z administratorem",
      "Authentication in progress...": "Uwierzytelnianie...",
      "Virtual dean's office": "Wirtualny Dziekanat",
      "Elearning platform": "Platforma e-learningowa",
      "Library": "Biblioteka ZPSB",
      "ZPSB email": "Poczta ZPSB",
      "Knowledge base": "Baza wiedzy",
      "Files": "Plikownia",
      "Could not login automatically. Interaction required.": "Nie można było zalogować automatycznie." +
      " Wymagana interakcja.",
      "To log in to the Navoica portal, create a new account using your university email address.":  
      "Żeby skorzystać z serwisu  Navoica, załóż nowe konto na uczelniany adres email.",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      convertDetectedLanguage: (lng) => lng.replace('-', '_')
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;