import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';

export const SignOutButton = () => {
    const { instance } = useMsal();
    const { t, i18n } = useTranslation();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <>
            <button className="link-logout" onClick={() => handleLogout()}>
                {t('Logout')}
            </button>
        </>
    )
};