import NavBarFontSwitcher from './NavBarFontSwitcher';
import NavBarContrastSwitcher from './NavBarContrastSwitcher';
import NavBarLangSwitcher from './NavBarLangSwitcher';

const NavBarBottom = () => {
    return (
        <>
            <nav className={`nav container bottom`}>
                <div className="row">
                    <NavBarFontSwitcher />
                    <NavBarContrastSwitcher />
                    <NavBarLangSwitcher />
                </div>
            </nav>
        </>
    );
};

export default NavBarBottom;