import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { ReactComponent as Logo } from './../styles/logo-horizontal.svg';
import { ReactComponent as LogoEn } from './../styles/logo-horizontal-en.svg';
import NavBarFontSwitcher from './NavBarFontSwitcher';
import NavBarContrastSwitcher from './NavBarContrastSwitcher';
import NavBarLangSwitcher from './NavBarLangSwitcher';

const NavBarTop = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <>
            <nav className={`nav container top`}>
                <div className="row">
                    <div className="col col-md-auto logo">

                        {(i18n.language == "pl" &&
                        <Logo />)
                        || 
                        <LogoEn />
                        }
                    </div>
                    <NavBarFontSwitcher></NavBarFontSwitcher>
                    <NavBarContrastSwitcher></NavBarContrastSwitcher>
                    <NavBarLangSwitcher></NavBarLangSwitcher>
                </div>
            </nav>
        </>
    );
};

export default NavBarTop;
