import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const NavBarFontSwitcher = () => {
    const { t } = useTranslation();
    const [fontSize, setFontSize] = useState("font-default");

    useEffect(() => {
        const data = window.localStorage.getItem('FONT_SIZE');
        if (data !== null) setFontSize(data);
    }, []);

    useEffect(() => {
        window.localStorage.setItem('FONT_SIZE', fontSize);
        document.querySelector(".page").classList.add(fontSize);
    }, [fontSize]);

    const replaceFontSize = (fontSizeToSet) => {
        if (fontSize == fontSizeToSet) {
            return
        }
        document.querySelector(".page").classList.remove(fontSize);
        setFontSize(fontSizeToSet)
    }
    return (
        <>
            <div className="col col-md-auto font-switcher">
                <h5>
                    {t('Font')}:
                </h5>
                <ul>
                    <li>
                        <button onClick={() => replaceFontSize("font-default")} >A</button>
                    </li>
                    <li>
                        <button onClick={() => replaceFontSize("font-bigger")} >A+</button>
                    </li>
                    <li>
                        <button onClick={() => replaceFontSize("font-the-biggest")} >A++</button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default NavBarFontSwitcher;