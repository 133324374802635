import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const NavBarLangSwitcher = () => {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.resolvedLanguage);

    useEffect(() => {
        const data = window.localStorage.getItem('LANG');
        if (data !== null) setLang(data);
    }, []);

    useEffect(() => {
        window.localStorage.setItem('LANG', lang);
        i18n.changeLanguage(lang);
        document.querySelector(".page").classList.add(lang);
        document.querySelector("html").setAttribute("lang", lang);
    }, [lang]);

    const changeLanguage = (lng) => {
        if (lang == lng) {
            return
        }
        document.querySelector(".page").classList.remove(lang);
        document.querySelector("html").setAttribute("lang", lang);
        i18n.changeLanguage(lng);
        setLang(lng)
    };


    return (
        <>
            <div className="col col-md-auto lang-switcher">
                <h5>
                    {t('Language')}:
                </h5>
                <ul>
                    <li>
                        <button onClick={() => changeLanguage('pl')} >PL</button>
                    </li>
                    <li>
                        <button onClick={() => changeLanguage('en')} >EN</button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default NavBarLangSwitcher;